import React from "react"
import SEO from "../components/seo"
import Layout from "../layouts/default"
import Nav from "../components/Nav"
import styled from "styled-components"
import { Link } from "gatsby"

const Flex = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.25rem;
`

const NotFoundPage = () => (
  <Layout>
    <Nav />
    <SEO title="Page not found" />
    <Flex>
      <div>
        <h1 css=" padding-bottom: 1rem;">There's nothing here</h1>
        <Link to="/">Take me home</Link>
      </div>
    </Flex>
  </Layout>
)

export default NotFoundPage
